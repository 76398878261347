<template>
    <div id="app-inner">
        <Header />
        <router-view></router-view>
        <Footer />
    </div>
</template>



<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
    name: 'App',
    components: {
        Header,
        Footer,
    },
    created: function() {
        this.$store.dispatch('loadInitialData');
    }

};
</script>



<style>
@font-face {
    font-family: "SourceSansPro";
    src: url("./assets/fonts/SourceSansPro-Regular.ttf");
    font-display: swap;
}

#app-inner {
    font-family: SourceSansPro, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f8f8;
}

/* Popup ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.close-popup {
    /*color: #F00;*/
    float: right;
    margin-right: 7px;
    display: inline-block;
    /*width: 8px;*/
    margin-top: 6px;
}

.close-popup:hover {
    cursor: pointer;
    /*font-weight: bold;*/
}

.product-popup-title {
    text-align: center;
}

.product-raw-json-wraper {
    overflow: scroll;
    padding: 8px 14px;
    height: 600px;
}

.product-raw-json {
    text-align: left;
    border-radius: 5px;
}

.product-popup-title {
    font-weight: bold;
    margin-top: 10px;
}
</style>
