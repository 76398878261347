import { createStore } from 'vuex';

const BASE_URL = process.env.VUE_APP_API_URL;

//  Vuex stuff ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const store = createStore({
    state() {
        return {
            // General ~~~~~~~~~~~~~~~~~~~~~~~~~~
            tree: [],
            featuredProducts: [],
            cart: [],

            // Catalog & Category ~~~~~~~~~~~~~~~
            products: [],
            curentCategorySlug: null,
            start: 0,
            limit: 50,
            sort: 1,

            // User ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            userIsLoged: false,
            userName: '',
            userEmail: '',
        };
    },
    getters: {
        userIsLoged(state) {
            return state.userIsLoged;
        },
        userName(state) {
            return state.userName;
        },
        userEmail(state) {
            return state.userEmail;
        },
        cartTotalPrice(state) {
            let cartTotal = 0;
            for (const product of state.cart) {
                cartTotal += product.cart_amount * product.regular_price;
            }
            return cartTotal.toFixed(2);
        },
        itemsInCart(state) {
            return state.cart.length;
        },
        amountInCart: (state) => (productID) => {
            for (const p of state.cart) {
                if (p.product_id === productID) return p.cart_amount;
            }
            return 0;
        },
        curentCategory(state) {
            let curentCategory = null;
            if (state.curentCategorySlug) {
                for (const category of state.tree) {
                    if (category.slug === state.curentCategorySlug) {
                        curentCategory = category;
                        break;
                    }
                }
            }
            return curentCategory;
        },
        currentCategoryName(state, getters) {
            let name = null;
            const category = getters.curentCategory;
            if (category) {
                name = category.name;
            }
            return name;
        },
        currentCategoryProductsCount(state, getters) {
            let count = null;
            const category = getters.curentCategory;
            if (category) {
                count = category.counter;
            }
            return count;
        }
    },
    mutations: {
        updateTree(state, payload) {
            state.tree = [];
            state.tree.push(...payload.tree);
            state.featuredProducts = [];
            state.featuredProducts.push(...payload.featuredProducts);
            console.log('InitialData loaded');
        },
        selectCategory(state, slug) {
            state.curentCategorySlug = slug;
        },
        updateProducts: (state, payload) => {
            if (payload.clearProducts) state.products = [];
            state.products.push(...payload.products);
        },
        updateProductsCartAmount: (state, getters) => {
            const itemsInCart = state.cart.map(p => p.product_id);
            state.products.forEach(p => {
                if (itemsInCart.includes(p.product_id)) {
                    p.cart_amount = getters.amountInCart(p.product_id);
                } else {
                    p.cart_amount = 0;
                }
            });
        },
        changeStart(state, newStart) {
            state.start = newStart;
        },
        changeSorting(state, newSorting) {
            state.sort = newSorting;
        },
        addProductToCart(state, newProduct) {
            // check if it already in the Cart
            for (const p of state.cart) {
                if (p.product_id === newProduct.product_id) {
                    p.cart_amount += 1;
                    return;
                }
            }
            // add if is not in Cart
            newProduct.cart_amount = 1;
            state.cart.push(newProduct);
        },
        minusProductFromCart(state, newProduct) {
            // check if it already in the Cart
            let index = 0;
            for (const p of state.cart) {
                if (p.product_id === newProduct.product_id) {
                    p.cart_amount -= 1;
                    if (p.cart_amount < 1) state.cart.splice(index, 1);
                    return;
                }
                index += 1;
            }
        },
        removeProductFromCart(state, newProduct) {
            let index = 0;
            for (const p of state.cart) {
                if (p.product_id === newProduct.product_id) {
                    state.cart.splice(index, 1);
                    newProduct.cart_amount = 0;
                    return;
                }
                index += 1;
            }
        },
        loginUser(state, payload) {
            state.userIsLoged = true;
            state.userName = payload.userName;
            state.userEmail = payload.userEmail;
        }

    },
    actions: {
        addProductToCart(context, product) {
            context.commit('addProductToCart', product);
            context.commit('updateProductsCartAmount', context.getters);
        },
        minusProductFromCart(context, product) {
            context.commit('minusProductFromCart', product);
            context.commit('updateProductsCartAmount', context.getters);
        },
        removeProductFromCart(context, product) {
            context.commit('removeProductFromCart', product);
            context.commit('updateProductsCartAmount', context.getters);
        },

        async 'loadInitialData'(context) {
            try {
                await fetch(BASE_URL + '/start/', {
                    method: 'get'
                }).then(function(response) {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(`Error ${response.status}`);
                    }
                }).then(data => {
                    if (data.error) {
                        alert('ERROR: ' + JSON.stringify(data.error));
                        throw data.error;
                    } else {
                        context.commit('updateTree', {
                            tree: data.response.tree,
                            featuredProducts: data.response.featured_products
                        });
                        // eventBus.$emit('initialDataLoaded');
                    }
                });
            } catch (err) {
                console.log('Request error:', err);
            }
        },

        async 'loadCategoryProducts'(context, clearProducts) {
            try {
                const url = `${BASE_URL}/category/${context.state.curentCategorySlug}/?start=${context.state.start}&limit=${context.state.limit}&sort=${context.state.sort}`;
                return await fetch(url, {
                    method: 'get'
                }).then(function(response) {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(`Error ${response.status}`);
                    }
                }).then(data => {
                    if (data.error) {
                        alert('ERROR: ' + JSON.stringify(data.error));
                        throw data.error;
                    } else {
                        context.commit('updateProducts', {
                            products: data.response.products,
                            clearProducts: clearProducts,
                            getters: context.getters,
                        });
                        context.commit('updateProductsCartAmount', context.getters);
                        // eventBus.$emit('productsLoaded');
                    }
                });
            } catch (err) {
                console.log('Request error:', err);
            }
        }
    }
});
