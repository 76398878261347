<template>
    <div>
        Error 404. Page not found :(
    </div>
</template>

<style scoped>
    div {
        font-size: 150%;
        display: flex;
        height: calc(100vh - 292px);
        justify-content: center;
        align-items: center;
        padding-bottom: 200px;
        min-height: 300px;
    }
</style>
