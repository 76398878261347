export const BASE_URL = process.env.VUE_APP_API_URL;


export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const sendSignUpToServer = async (userName, userEmail, userPassword) => {
    try {
        return await fetch(`${BASE_URL}/sign-up/`, {
            method: 'post',
            body: JSON.stringify({
                userName,
                userEmail,
                userPassword,
            })
        }).then(function(response) {
            console.log(response.status);
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }
        }).then(data => {
            console.log('SignUp response:', data);
            if (data.error) {
                alert('ERROR: ' + JSON.stringify(data.error));
                throw data.error;
            } else {
                // if (data['data'].length > 0) {
                //     allCategories.push(...data['data']);
                //     revisionFrom = data['revision-from'];
                //     loadCategories(selectedRetailer, selectedEnv, revisionFrom, n - 1)
                // } else {
                //     eventBus.$emit('categoriesLoaded');
                // }
            }
        });
    } catch (err) {
        console.log(err);
        return err;
    }
};

