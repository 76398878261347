<template>
    <div>
        <div class="header">
            <div v-if="userIsLoged" id="header-login-stuff">
                {{ userTitle()}}
            </div>
            <div v-else id="header-login-stuff">
                <router-link to="/sign-in/" class="tag is-link is-rounded">
                    Sign In
                </router-link>
                <router-link to="/sign-up/" class="tag is-link is-rounded">
                    Sign Up
                </router-link>
            </div>
            <h1>
                <router-link to="/">
                    <span id="beta-label">beta</span>
                    Kramnytsya
                </router-link>
            </h1>
            <div class="sub-header">e-grocery shop on steroids</div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'Header',
    computed: {
        userIsLoged() {
            return this.$store.getters.userIsLoged;
        },
        userName() {
            return this.$store.getters.userName;
        },
        userEmail() {
            return this.$store.getters.userEmail;
        }
    },
    methods: {
        userTitle() {
            if (this.userName !== '') return this.userName;
            return this.userEmail;
        }
    }
};
</script>



<style scoped>
.header {
    background-color: #8bd09d;
    text-align: center;
    padding-bottom: 5px;
    padding: 8px;
}

h1 {
    font-size: 250%;
    margin-top: -5px;
    /*text-shadow: 1px 1px 1px #EEE;*/
    text-shadow: -1px -1px 0 #EEE, 1px 1px 1px #333;
    font-weight: bold;
    letter-spacing: 5px;
}

h1 a {
    /*color: #333;*/
    /*color: #9D8BD0;*/
    color: #5A9E93;
    position: relative;
}

.sub-header {
    font-size: 111%;
    margin-top: -10px;
    /*text-shadow: 1px 1px 0 #555;*/
    letter-spacing: 2px;
}

.logout-button {
    float: right;
    margin: 1px 5px;
    font-size: 80%;
}

.logout-button:hover {
    cursor: pointer;
    color: #009;
}

#beta-label {
    letter-spacing: normal;
    position: absolute;
    right: -40px;
    top: 5px;
    transform: rotate(45deg);
    font-size: 50%;
    /*    color: #eee;
    text-shadow: 1px 1px 0px #555;*/
    color: #555;
    text-shadow: none;
    font-weight: normal;
}

/* Login stuff */

#header-login-stuff {
    position: absolute;
    right: 10px;
    top: 3px;
}

#header-login-stuff a {
    margin-left: 15px;
    cursor: pointer;
}

#header-login-stuff a:hover {
    /*color: #CCF;*/
}
</style>
