<template>
    <div id="sign-up-form-wrapper">
        <div id="sign-up-form">
            <div id="sign-up-form-input-field-name">
                <input class="input is-small" type="text" placeholder="Name" v-model="userName">
            </div>
            <div id="sign-up-form-input-field-email">
                <div v-show="emailError1" class="sign-up-form-error" id="email-error-message-1">
                    email is a required field
                </div>
                <div v-show="emailError2" class="sign-up-form-error" id="email-error-message-2">
                    please enter a valid email
                </div>
                <input class="input is-small" type="text" placeholder="Email" v-model="userEmail">
            </div>
            <div id="sign-up-form-input-field-password">
                <div v-show="passwordError1" class="sign-up-form-error" id="password-error-message-1">
                    password is a required field
                </div>
                <div v-show="passwordError2" class="sign-up-form-error" id="password-error-message-2">
                    password is too short (must be 8 characters or more)
                </div>
                <input class="input is-small" type="password" placeholder="Password" v-model="userPassword">
            </div>
            <div id="sign-up-form-button">
                <input class="button is-ghost" type="submit" value="Sign Up" @click="signUp">
            </div>
        </div>
    </div>
</template>



<script>
import { BASE_URL, emailRegExp } from '@/utils';

export default {
    name: 'SignUp',
    data: function() {
        return {
            userName: '',
            userEmail: '',
            userPassword: '',
            emailError1: false,
            emailError2: false,
            passwordError1: false,
            passwordError2: false,
        };
    },
    methods: {
        signUp() {
            let everythingOK = true;
            this.emailError1 = false;
            this.emailError2 = false;
            this.passwordError1 = false;
            this.passwordError2 = false;

            if (this.userEmail.trim().length === 0) {
                this.emailError1 = true;
                everythingOK = false;
            } else {
                if (!emailRegExp.test(this.userEmail)) {
                    this.emailError2 = true;
                    everythingOK = false;
                }
            }
            if (this.userPassword.trim().length === 0) {
                this.passwordError1 = true;
                everythingOK = false;
            } else {
                if (this.userPassword.trim().length < 8) {
                    this.passwordError2 = true;
                    everythingOK = false;
                }
            }

            if (!everythingOK) return;
            this.sendSignUpToServer(this.userName, this.userEmail, this.userPassword);
        },
        async sendSignUpToServer(userName, userEmail, userPassword) {
            try {
                return await fetch(`${BASE_URL}/sign-up/`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json; utf-8'
                    },
                    body: JSON.stringify({
                        userName,
                        userEmail,
                        userPassword,
                    })
                }).then(function(response) {
                    console.log(response.status);
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error(`Error ${response.status}: ${response.statusText}`);
                    };
                }).then(data => {
                    console.log('SignUp response:', data);
                    if (data.status === 'ok' || data.status === '200') {
                        alert('You have successfully registered!');

                        this.$store.commit('loginUser', { userName, userEmail });
                        this.$router.push('/');
                    } else {
                        alert('ERROR: ' + data.message);
                    }
                });
            } catch (err) {
                console.log(err);
                alert(`Server error. Try again later.\n\n\n ${err}`);
                return err;
            }
        }
    }
};
</script>



<style scoped>
#sign-up-form-wrapper {
    display: flex;
    height: calc(100vh - 293px);
    min-height: 400px;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px
}

#sign-up-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 240px;
    padding: 15px 30px;

    border-radius: 3px;
    background-color: #D0ECD7;
    border: solid 1px #DBDBDB;
    box-shadow: 2px 2px 5px #9f9f9f;
}

#sign-up-form input {
    width: 300px;
}

#sign-up-form-button {
    text-align: center;
}

#sign-up-form-button input {
    height: 30px;
    font-size: 15px;
    cursor: pointer;
    background-color: #8bd09d;
    padding: 3px 20px;
    border-radius: 3px;
    border-color: #8bd09d;
    text-align: center;
    box-shadow: 1px 1px 1px #777, -1px -1px 2px #FFF;
}

#sign-up-form-button input:hover {
    color: #33F;
}

#sign-up-form-button input:focus {
    border-color: rgb(50, 115, 220);
}

.sign-up-form-error {
    font-size: 80%;
    color: #f33;

}
</style>
