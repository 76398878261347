<template>
    <div class="catalog-wraper">
        <div v-for="c in tree" :key="c.id" class="one-category">
            <div>
                <router-link :to="{name: 'category', params: {categorySlug: c.slug}}" tag="span" class="one-category-link" active-class="selected">
                    {{ c.name }}
                </router-link>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'LeftColumn',
    data: function() {
        return {};
    },
    computed: {
        tree() {
            return this.$store.state.tree;
        },
        selectedCategorySlug() {
            return this.$store.state.curentCategorySlug;
        }
    },
    watch: {
        $route: {
            handler(newVal) {
                this.selectCategory(newVal.params.categorySlug);
            },
            deep: true,
        }
    },
    created: function() {
        this.selectCategory(this.$route.params.categorySlug);
    },
    methods: {
        selectCategory: function(slug) {
            this.$store.commit('selectCategory', slug);
            this.$store.dispatch('loadCategoryProducts', true);
        }
    }
};
</script>



<style scoped>
.catalog-wraper {
    margin-top: 20px;
    /*margin-left: 5px;*/
}

.one-category {
    margin: 8px 10px 8px 15px;
    text-align: left;
}

.one-category-link:hover {
    cursor: pointer;
    color: #6B6;
}

.one-category-link.selected {
    color: #393;
    font-weight: bold;
}
</style>
