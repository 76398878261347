<template>
    <div class="content-wrapper">
        <div v-if="products && products.length > 0" class="content">

            <div class="category-sorting">
                Sort by
                <select v-model="categorySorting" @change="changeSorting">
                    <option value="1" selected="1">Popularity</option>
                    <option value="2">Price: Lowest First</option>
                    <option value="3">Price: Highest First</option>
                </select>
            </div>

            <div class="category-title">
                {{ currentCategoryName }}
                <span class="category-title-count">({{ products.length }} of {{ totalProducts }})</span>
            </div>

            <ProductCard v-for="(p, index) in products" :key="p.product_id" :product="p" :index="index" :cart_amount="p.cart_amount" />

            <div v-if="isMoreProducts" class="show-more-button-wrapper">
                <span class="show-more-button" @click="loadMore()">Load more</span>
            </div>
        </div>

        <div v-else class="blank-content">
        </div>

        <PopUp :showPopup="showPopup" :height="630" @closePopupEvent="closePopup">
            <div v-if="activeProduct">
                <div class="product-popup-title">{{ activeProduct['name'] }}</div>
                <div class="product-raw-json-wraper">
                    <img :src="activeProduct.big_image">
                </div>
            </div>
        </PopUp>

    </div>
</template>



<script>
import ProductCard from '@/components/ProductCard';
import PopUp from '@/components/PopUp';

export default {
    name: 'Content',
    components: {
        ProductCard,
        PopUp
    },
    data: function() {
        return {
            activeProduct: null,
            categorySorting: 1,
            showPopup: false
        };
    },
    computed: {
        isMoreProducts() {
            if (this.totalProducts > (this.$store.state.start + this.$store.state.limit)) {
                return true;
            }
            return false;
        },
        currentCategoryName() {
            return this.$store.getters.currentCategoryName;
        },
        totalProducts() {
            return this.$store.getters.currentCategoryProductsCount;
        },
        products() {
            return [...this.$store.state.products];
        }
    },
    created: function() {
        this.emitter.on('productPopup', (product) => {
            this.activeProduct = product;
            this.showPopup = true;
        });
    },
    methods: {
        loadMore: function() {
            this.$store.commit('changeStart', this.$store.state.start + this.$store.state.limit);
            this.$store.dispatch('loadCategoryProducts', false);
        },
        changeSorting: function() {
            this.$store.commit('changeSorting', this.categorySorting);
            this.$store.commit('changeStart', 0);
            this.$store.dispatch('loadCategoryProducts', true);
        },
        closePopup: function() {
            this.showPopup = false;
        },
        checkItemInCart: function(productId) {
            for (const p of this.$store.state.cart) {
                if (p.product_id === productId) {
                    return p.cart_amount;
                }
            }
            return 0;
        }
    }
};
</script>



<style scoped>
.content-wrapper {
    display: flex;
}

.blank-content {
    width: 100%;
    height: 100vh;
}

.content {
    text-align: center;
    padding: 0 20px 0 20px;
}

.category-title {
    text-align: left;
    font-size: 150%;
    margin: 13px 20px 20px 0;
}

.category-title-count {
    font-size: 70%;
    color: #BBB;
}

.category-sorting {
    float: right;
    margin-top: 18px;
    margin-right: 20px;
}

.category-sorting select {
    color: #359c50;
}

/* Show more button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.show-more-button-wrapper {
    text-align: center;
}

.show-more-button {
    color: #FFF;
    background-color: #9D8BD0;
    padding: 3px 15px;
    border-radius: 3px;
    text-align: center;
    box-shadow: 1px 1px 1px #AAA;
    text-shadow: 1px 1px 0px #555;
}

.show-more-button:hover {
    cursor: pointer;
}
</style>
