import { createApp, h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import VueCookies from 'vue3-cookies';
import mitt from 'mitt';

import App from '@/App.vue';
import { store } from '@/models';
import MainPage from '@/components/MainPage.vue';
import Catalog from '@/components/Catalog.vue';
import Category from '@/components/Category.vue';
import SignIn from '@/components/SignIn.vue';
import SignUp from '@/components/SignUp.vue';
import Error404 from '@/components/Error404.vue';

require('@/assets/main.scss');

const routes = [
    { path: '/', component: MainPage },
    { path: '/catalog/', component: Catalog },
    { path: '/catalog/:categorySlug/', component: Category, name: 'category', strict: true },
    { path: '/sign-in/', component: SignIn },
    { path: '/sign-up/', component: SignUp },
    { path: '/:pathMatch(.*)*', component: Error404, name: 'Error404' }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

const VueApp = {
    data() {
        return {};
    },
    mounted() {},
    render: () => h(App)
};

const myapp = createApp(VueApp);

myapp.use(router);
myapp.use(store);
myapp.use(VueCookies);

const emitter = mitt();
myapp.config.globalProperties.emitter = emitter;

myapp.mount('#app');
