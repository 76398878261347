<template>
    <div class="one-product">
        <div class="one-product-number">#{{ index + 1 }}</div>
        <div v-if="product.main_image" class="one-product-image" @click="showPopup(product)">
            <img :src="product.main_image">
        </div>
        <div v-else class="one-product-image">
            <img src="@/assets/images/no_image.jpg">
        </div>
        <div v-if="product.regular_price">
            <div class="one-product-units">{{ product.size }}</div>
            <div class="one-product-price">${{ product.regular_price }}</div>
        </div>
        <div v-else class="one-product-absent">not available</div>
        <div class="one-product-name">{{ product.name }}</div>
        <div v-if="cart_amount" class="one-product-edit-amount">
            <span class="one-product-edit-amount-minus" @click="removeFromCart(product)">
                <img src="@/assets/images/minus.png">
            </span>
            <span class="one-product-edit-amount-quantity">
                <div class="quantity-value">{{ product.cart_amount }}</div>
                <div class="amount-help-text">in your cart</div>
            </span>
            <span class="one-product-edit-amount-plus" @click="addToCart(product)">
                <img src="@/assets/images/plus.png">
            </span>
        </div>
        <div v-else class="one-product-add-to-cart-button" @click="addToCart(product)">Add to Cart</div>
    </div>
</template>



<script>
export default {
    name: 'ProductCard',
    props: ['product', 'cart_amount', 'index'],
    methods: {
        addToCart: function(product) {
            this.$store.dispatch('addProductToCart', product);
        },
        removeFromCart: function(product) {
            this.$store.dispatch('minusProductFromCart', product);
        },
        showPopup: function(product) {
            this.emitter.emit('productPopup', product);
        }
    }
};
</script>



<style scoped>
/* One product ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.one-product {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
    /*border: solid 1px #EEE;*/
    box-shadow: 2px 2px 5px #bdbdbd;
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    width: 160px;
    height: 270px;
    position: relative;
    background-color: #FFF;
}

.one-product-number {
    text-align: right;
    font-size: 80%;
    color: #BBB;
    position: absolute;
    right: 5px;
    top: 2px;
}

.one-product-image {
    text-align: center;
    width: 120px;
    height: 100px;
    margin: 10px 10px;
}

.one-product-image img {
    max-width: 100px;
    max-height: 100px;
}

.one-product-name {
    /*font-weight: bold;*/
    font-size: 90%;
    line-height: 100%;
    height: 43px;
    overflow: hidden;
    margin-top: 5px;
}

.one-product-price {
    font-weight: bold;
}

.one-product-units {
    font-size: 80%;
    color: #BBB;
    float: right;
    margin-top: 3px;

}

.one-product-add-to-cart-button {
    font-size: 90%;
    color: #333;
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: #8bd09d;
    padding: 3px 5px;
    border-radius: 3px;
    text-align: center;
    box-shadow: 1px 1px 1px #AAA;
}

.one-product-add-to-cart-button:hover {
    cursor: pointer;
}

.one-product-show-json:hover,
.one-product-show-image:hover {
    cursor: pointer;
    color: #3a3;
}

.product-raw-json-wraper {
    height: 600px;
    text-align: center;
}

.product-raw-json-wraper img {
    max-width: 570px;
    max-height: 570px;
}

.one-product-absent {
    color: #af2929;
}

/* Edit amount ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.one-product-edit-amount {
    /*width: 100%;*/
    height: 26px;
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
}

.one-product-edit-amount-minus,
.one-product-edit-amount-plus {
    display: inline-block;
    width: 28px;
    height: 28px;
    border: solid 1px #efecf3;
    border-radius: 2px;
    text-align: center;
    /*padding: 3px;*/
    cursor: pointer;
}

.one-product-edit-amount-minus:hover,
.one-product-edit-amount-plus:hover {
    box-shadow: 1px 1px 2px #AAA;
}

.one-product-edit-amount-minus {
    float: left;
    background-color: #FFF3F3;
}

.one-product-edit-amount-plus {
    float: right;
    background-color: #F3FFF3;
}

.one-product-edit-amount-quantity {
    text-align: center;
    display: inline-block;
    width: 84px;
    height: 28px;
    position: relative;
}

.quantity-value {
    position: absolute;
    top: -5px;
    width: 100%;
    font-weight: bold;
}

.amount-help-text {
    font-size: 70%;
    color: #BBB;
    position: absolute;
    left: 16px;
    top: 14px;
}
</style>
