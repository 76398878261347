<template>
    <div v-if="showPopup" class="popup-wraper" :style="{width: finalWidth + 'px', height: finalHeight + 'px' }">
        <button class="delete close-popup" @click="closePopup">x</button>
        <slot></slot>
    </div>
    <div v-if="showPopup" class="shadow" @click="closePopup"></div>
</template>



<script>
export default {
    name: 'PopUp',
    props: ['showPopup', 'width', 'height'],
    emits: ['closePopupEvent'],
    data: function() {
        return {

        };
    },
    computed: {
        finalWidth() {
            return this.width || 600;
        },
        finalHeight() {
            return this.height || 400;
        }
    },
    created: function() {

    },
    methods: {
        closePopup() {
            this.$emit('closePopupEvent');
        }
    }
};
</script>



<style scoped>
.popup-wraper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    z-index: 9999;

    background-color: #FFF;
    box-shadow: 1px 1px 3px #999;
}

.close-popup {
    float: right;
    cursor: pointer;
}

.shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
    z-index: 9998;
}
</style>
