<template>
    <div class="main-page">
        <div class="main-slider">

            <transition :name="transitionAnimation">
                <div v-if="selectedSlide == 1" key="slide-1" id="slide-1-wrapper">
                    <section id="slide-1" class="hero is-success is-halfheight">
                        <div class="hero-body">
                            <div class="hero-body-text">
                                <p class="title">
                                    Fresh vegetables
                                </p>
                                <p class="subtitle">
                                    100% Organic Products
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <div v-else-if="selectedSlide == 2" key="slide-2" id="slide-2-wrapper">
                    <section id="slide-2" class="hero is-success is-halfheight">
                        <div class="hero-body">
                            <div class="hero-body-text">
                                <p class="title">
                                    Fresh fruits
                                </p>
                                <p class="subtitle">
                                    100% Organic Products
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <div v-else-if="selectedSlide == 3" key="slide-3" id="slide-3-wrapper">
                    <section id="slide-3" class="hero is-success is-halfheight">
                        <div class="hero-body">
                            <div class="hero-body-text">
                                <p class="title">
                                    Fresh daily bakery
                                </p>
                                <p class="subtitle">
                                    with only the healthiest ingredients
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </transition>

            <span @click="previousSlide" id="left-slide-arrow"><img src="@/assets/images/icon_chevron_left.png"></span>
            <span @click="nextSlide" id="right-slide-arrow"><img src="@/assets/images/icon_chevron_right.png"></span>
        </div>

        <div class="shop-now-block">
            <router-link to="/catalog/">
                <span>Shop Now</span>
            </router-link>
        </div>

        <div class="columns promo-banners">
            <div class="column is-4">
                <router-link to="/catalog/">
                    <div class="promo-banner-1">
                        <span class="promo-banner-text">
                            Look at the offer of the most popular hits
                        </span>
                    </div>
                </router-link>
            </div>
            <div class="column is-4">
                <router-link to="/catalog/">
                    <div class="promo-banner-2">
                        <span class="promo-banner-text">
                            We offer several products in a bargain offer
                        </span>
                    </div>
                </router-link>
            </div>
            <div class="column is-4">
                <router-link to="/catalog/">
                    <div class="promo-banner-3">
                        <span class="promo-banner-text">
                            Get special offers with Clubcard membership
                        </span>
                    </div>
                </router-link>
            </div>
        </div>

        <div v-if="featuredProducts.length > 0" class="featured-products">
            <div class="featured-products-title">
                Featured Products
            </div>
            <ProductCard v-for="(p, index) in featuredProducts" :key="p.product_id" :product="p" :index="index" :cart_amount="p.cart_amount" />
        </div>

        <PopUp :showPopup="showPopup" :height="630" @closePopupEvent="closePopup">
            <div v-if="activeProduct">
                <div class="product-popup-title">{{ activeProduct['name'] }}</div>
                <div class="product-raw-json-wraper">
                    <img :src="activeProduct.big_image">
                </div>
            </div>
        </PopUp>

    </div>
</template>



<script>
import ProductCard from '@/components/ProductCard';
import PopUp from '@/components/PopUp';

export default {
    name: 'MainPage',
    components: {
        ProductCard,
        PopUp,
    },
    data: function() {
        return {
            selectedSlide: 1,
            slides: [
                { id: 1, name: 'slide-1' },
                { id: 2, name: 'slide-2' },
                { id: 3, name: 'slide-3' },
            ],
            transitionAnimation: 'slide',
            activeProduct: null,
            showPopup: false,
        };
    },
    computed: {
        featuredProducts() {
            return [...this.$store.state.featuredProducts];
        }
    },
    created: function() {
        this.interval = setInterval(() => {
            this.nextSlide();
        }, 4500);

        this.emitter.on('productPopup', (product) => {
            this.activeProduct = product;
            this.showPopup = true;
        });
    },
    methods: {
        nextSlide: function() {
            this.transitionAnimation = 'slide';
            if ((this.selectedSlide + 1) > this.slides.length) {
                this.selectedSlide = 1;
            } else {
                this.selectedSlide += 1;
            }
        },
        previousSlide: function() {
            this.transitionAnimation = 'slide-reverse';
            if ((this.selectedSlide - 1) < 1) {
                this.selectedSlide = this.slides.length;
            } else {
                this.selectedSlide -= 1;
            }
        },
        closePopup: function() {
            this.showPopup = false;
        },
    }
};
</script>



<style scoped>
.main-slider {
    position: relative;
    width: 100%;
    height: 50vh;
    box-shadow: 2px 2px 5px #bdbdbd;
}

#left-slide-arrow,
#right-slide-arrow {
    position: absolute;
    top: 47%;
    cursor: pointer;
}

#right-slide-arrow {
    right: 0;
}

.hero-body {
    padding-left: 5rem;
    padding-right: 5rem;
}

#slide-1-wrapper,
#slide-2-wrapper,
#slide-3-wrapper {
    position: absolute;
    width: 100%;
}

#slide-1 {
    background-image: url('~@/assets/images/slider_image_1.jpg');
    background-size: 100%;
}

#slide-2 {
    background-image: url('~@/assets/images/slider_image_2.jpg');
    background-size: 100%;
}

#slide-3 {
    background-image: url('~@/assets/images/slider_image_3.jpg');
    background-size: 100%;
}

.hero-body-text {
    background-color: rgba(1, 1, 1, 0.4);
    box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.4);
    padding: 30px;
    border-radius: 5px;
}

.hero-body-text p {
    text-shadow: 1px 1px 0 #000;
}

/* Slider animations ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.slide-enter {}

.slide-enter-active {
    animation: slide-in 0.75s ease-out forwards;
}

.slide-leave {}

.slide-leave-active {
    animation: slide-out 0.75s ease-out forwards;

}

@keyframes slide-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

/* reverse */

.slide-reverse-enter-active {
    animation: slide-reverse-in 0.75s ease-out forwards;
}

.slide-reverse-leave-active {
    animation: slide-reverse-out 0.75s ease-out forwards;

}

@keyframes slide-reverse-in {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-reverse-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

/* Shop Now block  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.shop-now-block {
    text-align: center;
    margin-top: 40px;
}

.shop-now-block span {
    display: inline-block;
    color: #FFF;
    text-shadow: 1px 1px 0 #555;
    font-weight: bold;
    background-color: #8bd09d;
    padding: 8px 40px;
    font-size: 1.6rem;
    border-radius: 3px;
    box-shadow: 2px 2px 5px #bdbdbd;
}

.shop-now-block span:hover {
    color: #EFEFEF;
    box-shadow: 2px 2px 6px #999;

}

/* Promo banners ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.promo-banners {
    margin-top: 10px;
    padding: 25px
}

.promo-banner-1,
.promo-banner-2,
.promo-banner-3 {
    height: 120px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    border: solid 1px #EEE;
    box-shadow: 2px 2px 5px #bdbdbd;
}

.promo-banner-1:hover,
.promo-banner-2:hover,
.promo-banner-3:hover {
    box-shadow: 2px 2px 6px #999;
}

.promo-banner-1 {
    background-image: url('~@/assets/images/banner_1.jpg');
    background-size: 100%;
}

.promo-banner-2 {
    background-image: url('~@/assets/images/banner_2.jpg');
    background-size: 100%;
}

.promo-banner-3 {
    background-image: url('~@/assets/images/banner_3.jpg');
    background-size: 100%;
}

.promo-banner-text {
    display: inline-block;
    position: absolute;
    width: 256px;
    right: -58px;
    top: -25px;
    padding: 60px 60px 60px 20px;
    border-radius: 100px;
    background-color: #D0ECD7;
    color: #4a4a4a;
}

/* Featured products ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.featured-products {
    margin: 25px;
    text-align: center;
    overflow: hidden;
    height: 690px;
}

.featured-products-title {
    font-size: 180%;
    margin-bottom: 20px;
    text-shadow: 1px 1px 0 #FFFFFF;
}
</style>
