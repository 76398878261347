<template>
    <div class="cart-wraper">
        <div class="cart-title">
            <span v-if="cart.length == 0" class="cart-total empty">Cart is empty</span>
            <span v-else class="cart-total">Cart total: <b>${{ cartTotal }}</b></span>
        </div>
        <div v-for="product in cart" :key="product.id" class="cart-one-product">
            <span class="amount-controls">
                <div class="plus-button" @click="increaseAmount(product)"><img src="@/assets/images/plus.png"></div>
                <div class="amount">{{ product.cart_amount }}</div>
                <div class="minus-button" @click="decreaseAmount(product)"><img src="@/assets/images/minus.png"></div>
            </span>
            <span class="cart-one-product-image">
                <img :src="product.main_image">
            </span>
            <span class="cart-one-product-name">{{ product.name }}</span>
            <div class="close-button" @click="removeProduct(product)">
                <img src="@/assets/images/close.png">
            </div>
            <div class="cart-one-product-price">${{ totalProductPrice(product) }}</div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'Cart',
    data: function() {
        return {
            selectedCategorySlug: null,
        };
    },
    computed: {
        cartTotal() {
            return this.$store.getters.cartTotalPrice;
        },
        cart() {
            return [...this.$store.state.cart];
        },
    },
    created: function() {

    },
    methods: {
        increaseAmount: function(product) {
            this.$store.dispatch('addProductToCart', product);
        },
        decreaseAmount: function(product) {
            this.$store.dispatch('minusProductFromCart', product);
        },
        removeProduct: function(product) {
            this.$store.dispatch('removeProductFromCart', product);
        },
        totalProductPrice: function(product) {
            const totalPrice = product.regular_price * product.cart_amount;
            return totalPrice.toFixed(2);
        }
    }
};
</script>



<style scoped>
.cart-wraper {
    min-width: 240px;
    width: 240px;
    background-color: #FFF;
    box-shadow: 1px 1px 3px #999;
}

.cart-title {
    text-align: right;
    /*font-size: 13pt;*/
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 20px;
    border-bottom: solid 1px #5A9E93;
    background-color: #8bd09d;
    /*    color: #FFFFFF;
    text-shadow: 1px 1px 0px #555;*/
}

.cart-title span {
    display: inline-block;
    vertical-align: middle;
    padding-right: 8px;
}

.cart-total {
    margin-top: -6px;
}

.cart-total.empty {
    text-align: center;
    display: inline-block;
    width: 100%;
}

.cart-one-product {
    padding: 8px 28px 8px 8px;
    border-bottom: solid 1px #5A9E93;
    display: flex;
    position: relative;
}

.cart-one-product span {
    display: inline-block;
}

.cart-one-product-name {
    font-size: 10pt;
    width: 119px;
    height: 47px;
    overflow: hidden;
    line-height: 15px;
}

.cart-one-product-image {
    min-width: 45px;
    width: 45px;
    margin: 0 5px;
    height: 100%;
    vertical-align: middle;
    margin-top: 3px;
    text-align: center;
}

.cart-one-product-image img {
    max-width: 45px;
    max-height: 45px;
}

.amount-controls {}

.close-button,
.plus-button,
.minus-button {
    opacity: 0.8;
    width: 20px;
    height: 20px;
    border: solid 1px #B6A1D1;
    border-radius: 2px;
    text-align: center;
}

.close-button:hover,
.plus-button:hover,
.minus-button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 2px #393;
}

.amount {
    text-align: center;
}

.cart-one-product-price {
    position: absolute;
    right: 28px;
    bottom: 6px;
    font-weight: bold;
}
</style>
