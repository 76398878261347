<template>
    <div>
        <div class="action-bar">
            <div class="departments">
                <span @click="toggleDepartments">Departments</span>
                <span @click="toggleDepartments" id="chevron"><img src="@/assets/images/icon_chevron_down.png"></span>
            </div>
            <div class="search-bar">
                <span id="input-field">
                    <input @keyup.enter="showNotYetPopup" class="input is-small" type="text" placeholder="Search">
                </span>
                <span>
                    <span @click="showNotYetPopup" id="search-icon"><img src="@/assets/images/icon_search.png"></span>
                </span>
            </div>
            <div class="cart">
                <span v-if="itemsInCart > 0" id="cart-counter">{{ itemsInCart }}</span>
                <span @click="toggleCart" id="cart-icon"><img src="@/assets/images/cart-outline-white.png"></span>
            </div>
        </div>
        <div id="catalog">
            <transition name="slide-left">
                <div v-show="showDepartments" id="left-column">
                    <LeftColumn />
                </div>
            </transition>
            <div id="center-column">
                <Content />
            </div>
            <transition name="slide-right">
                <div v-show="showCart" id="right-column">
                    <Cart />
                </div>
            </transition>
        </div>
        <PopUp :showPopup="showPopup" :width="300" :height="150" @closePopupEvent="closePopup">
            <div class="not-yet-popup-content">
                <p>Search has not been implemented yet :(</p>
            </div>
        </PopUp>
    </div>
</template>



<script>
import LeftColumn from './LeftColumn.vue';
import Content from './Content.vue';
import Cart from '@/components/Cart.vue';
import PopUp from '@/components/PopUp';

export default {
    name: 'Category',
    components: {
        LeftColumn,
        Content,
        Cart,
        PopUp,
    },
    data: function() {
        return {
            showDepartments: true,
            showCart: true,
            showPopup: false,
        };
    },
    computed: {
        itemsInCart() {
            return this.$store.getters.itemsInCart;
        },
    },
    methods: {
        toggleDepartments() {
            this.showDepartments = !this.showDepartments;
        },
        toggleCart() {
            this.showCart = !this.showCart;
        },
        showNotYetPopup: function() {
            this.showPopup = true;
        },
        closePopup: function() {
            this.showPopup = false;
        },
    },
};
</script>



<style scoped>
.action-bar {
    color: #FFF;
    /*text-shadow: 1px 1px 0 #555;*/
    background-color: #5A9E93;
    height: 60px;
    display: flex;
    align-items: center;
}

/* Departments block ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.departments {
    width: 200px;
    text-align: left;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.departments span {
    display: inline-block;
    cursor: pointer;
}

#chevron {
    margin-top: 7px;
}

.departments img {
    max-width: 22px;
}

/* Search block ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.search-bar {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

#input-field {
    width: 100%
}

#search-icon {
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}

#search-icon img {
    max-width: 30px;
    margin-top: 6px;
}

/* Cart block ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.cart {
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    position: relative;
}

#cart-icon img {
    max-width: 26px;
}

#cart-icon {
    margin-top: 7px;
    cursor: pointer;
}

#cart-counter {
    position: absolute;
    top: -2px;
    right: 18px;
    display: inline-block;
    text-align: center;
    background-color: #F33;
    padding: 0 3px;
    border-radius: 12px;
    font-size: 9pt;
    min-width: 18px;
}

/* Catalog stuff ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#catalog {
    display: flex;
}

#left-column {
    width: 200px;
    min-width: 200px;
}

#center-column {
    flex-grow: 1;
}

#right-column {
    width: 230px;
    min-width: 230px;
}

/* Slide left animations ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.slide-left-enter {}

.slide-left-enter-active {
    animation: slide-left-in 0.5s ease-out forwards;
}

.slide-left-leave {}

.slide-left-leave-active {
    animation: slide-left-out 0.5s ease-out forwards;

}

@keyframes slide-left-in {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-left-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

/* Slide right animations ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.slide-right-enter {}

.slide-right-enter-active {
    animation: slide-right-in 0.5s ease-out forwards;
}

.slide-right-leave {}

.slide-right-leave-active {
    animation: slide-right-out 0.5s ease-out forwards;

}

@keyframes slide-right-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-right-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

/* PopUp ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.not-yet-popup-content {
    text-align: center;
    padding: 50px;
}
</style>
