<template>
    <div>
        <div class="departments-title">
            Choose the Department
        </div>
        <div class="departments">
            <router-link :to="{name: 'category', params: {categorySlug: c.slug}}" tag="div" v-for="c in tree" :key="c.id" class="one-category">
                <span class="one-category-link">{{ c.name }}</span>
            </router-link>
        </div>
    </div>
</template>



<script>
export default {
    name: 'Catalog',
    data: function() {
        return {
            showDepartments: true,
            showCart: true,
        };
    },
    computed: {
        tree() {
            return this.$store.state.tree;
        }
    }
};
</script>



<style scoped>
.departments-title {
    text-align: center;
    font-size: 180%;
    margin-top: 30px;
    text-shadow: 1px 1px 0 #FFFFFF;
}

.departments {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: auto;
    justify-content: center;

    margin: 20px;
}

.one-category {
    flex: 0 0 auto;
    margin: 20px;
    width: 200px;
    height: 100px;

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 120%;
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    background-color: #D0ECD7;
    border: solid 1px #EEE;
    box-shadow: 2px 2px 5px #bdbdbd;
    transition: transform 250ms;

}

.one-category:hover {
    box-shadow: 2px 2px 6px #999;
    transform: scale(1.1);
}
</style>
