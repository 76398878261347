<template>
    <div class="site-footer">
        <div class="columns">
            <div class="column">
                <table class="contacts">
                    <tr>
                        <td>
                            <img src="@/assets/images/icon_home.png">
                        </td>
                        <td>
                            959 Bingamon Road <br>
                            Ashtabula, OH 44004
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src="@/assets/images/icon_phone.png">
                        </td>
                        <td>
                            440-536-5338
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img src="@/assets/images/icon_email.png">
                        </td>
                        <td>
                            support@examplel.com
                        </td>
                    </tr>
                </table>
            </div>
            <div class="column">
                <div class="hepl-links">
                    <div>Help</div>
                    <div>Privacy Policy</div>
                    <div>Shipping Details</div>
                    <div>Refund & Returns</div>
                </div>
            </div>
            <div class="column">
                <table class="last-column">
                    <tr class="credits">
                        <td><img src="@/assets/images/icon_credits.png"></td>
                        <td><a @click="showCredits">Credits</a></td>
                    </tr>
                    <tr class="powered">
                        <td><img src="@/assets/images/icon_power_plug.png"></td>
                        <td><a href="https://blokhin.us/kramnytsya/">Powered by Kramnytsya: e-grocery shop on steroids
                                <!-- or online store for humans --></a></td>
                    </tr>
                </table>
            </div>
        </div>
        <PopUp :showPopup="showPopup" :height="170" @closePopupEvent="closePopup">
            <div class="credits-popup-title">Credits</div>
            <div class="credits-popup-content">
                <div>
                    <span class="left-content">Products data and images:</span>
                    <span class="right-content"><a href="https://developer.kroger.com/">Kroger Developers</a></span>
                </div>
                <div>
                    <span class="left-content">Icons:</span>
                    <span class="right-content"><a href="https://materialdesignicons.com/">Material Design Icons</a></span>
                </div>
                <div>
                    <span class="left-content">Photos:</span>
                    <span class="right-content"><a href="https://unsplash.com">Unsplash</a></span>
                </div>
            </div>
        </PopUp>
    </div>
</template>



<script>
import PopUp from '@/components/PopUp';

export default {
    name: 'Footer',
    components: {
        PopUp
    },
    data: function() {
        return {
            showPopup: false,
        };
    },
    methods: {
        showCredits: function() {
            this.showPopup = true;
        },
        closePopup: function() {
            this.showPopup = false;
        }
    }
};
</script>



<style scoped>
.site-footer {
    background-color: #8bd09d;
    padding: 10px;
    margin-top: 40px;
    text-align: left;

    background-image: url('~@/assets/images/footer.jpg');
    background-size: 100%;
    min-height: 160px;

    color: #FFF;
}

.site-footer a {
    color: #FFF;
    /*text-shadow: 1px 1px 1px #EEE;*/

}

.site-footer a:hover {
    text-decoration: underline;
}

.contacts td,
.last-column td {
    padding: 5px 2px;
}

.contacts img,
.credits img,
.powered img {
    max-width: 26px;
    max-height: 26px;
}

.hepl-links div {
    margin: 5px 0 9px 0;
    /*cursor: pointer;*/
}

.credits,
.powered {
    margin: 5px 0 9px 0;
}

/* Popup ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.credits-popup-title,
.credits-popup-content {
    color: #4A4A4A;
}

.credits-popup-content a {
    color: #33F;
}

.credits-popup-title {
    text-align: center;
    margin: 5px;
    font-size: 150%;
}

.credits-popup-content {
    padding: 10px 20px;
}

.credits-popup-content div {
    margin-bottom: 10px;
}

.left-content,
.right-content {
    display: inline-block;
    width: 50%;
}

.left-content {
    text-align: right;
    padding-right: 8px;
}
</style>
